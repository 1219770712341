.map-wrap {
    position: relative;
    display:flex;
    flex-direction:row;
    height: calc(110vh);
  }
  
  .map {
    position:relative;
   width:100%;
   height:100%
  }

  .map_outer {
    pointer-events: none;
    z-index: 1;
    position:absolute;
    top:0;
    left:0;
    box-shadow:inset 0 0 600px #000000;
   width:100%;
   height:100%
  }